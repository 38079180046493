<template>
  <div>
    <section class="header-urundetay">

      <div>
        <div>
          <div class="urun"
               data-aos="fade-right"
               data-aos-anchor-placement="top-center"
               data-aos-delay="0"
               data-aos-duration="600"
               data-aos-easing="ease-in"
               data-aos-mirror="true"
               data-aos-offset="0"
               data-aos-once="false">
            <scroll-parallax :left="true" direction="x">
              <img :alt="product.name" :src="image">
            </scroll-parallax>
          </div>
          <div class="baslik"
               data-aos="fade-left"
               data-aos-anchor-placement="top-bottom"
               data-aos-delay="0"
               data-aos-duration="600"
               data-aos-easing="ease-in"
               data-aos-mirror="true"
               data-aos-offset="0"
               data-aos-once="false">
            <h3>{{ cats }}</h3>
            <h2>{{ product.name }}</h2>
          </div>
        </div>
        <img class="bg" src="../../../public/assets/img/urun-bg.jpg"/>
      </div>
    </section>

    <section class="icerik-urundetay">

      <div class="sol">
        <h3>{{ product.name }}</h3>
        <p data-aos="fade-up"
           data-aos-anchor-placement="top-bottom"
           data-aos-delay="0"
           data-aos-duration="600"
           data-aos-easing="ease-in"
           data-aos-mirror="true"
           data-aos-offset="0"
           data-aos-once="false">
          {{ product.desc }}
        </p>
        <center>
          <!--          <img src="../../../public/assets/img/orn-tablo.jpg"-->
          <!--               data-aos="fade-up"-->
          <!--               data-aos-offset="0"-->
          <!--               data-aos-delay="0"-->
          <!--               data-aos-duration="600"-->
          <!--               data-aos-easing="ease-in"-->
          <!--               data-aos-mirror="true"-->
          <!--               data-aos-once="false"-->
          <!--               data-aos-anchor-placement="top-bottom"/>-->
          <img :src="product.table"
               data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-delay="0"
               data-aos-duration="600"
               data-aos-easing="ease-in"
               data-aos-mirror="true"
               data-aos-offset="0"
               data-aos-once="false"/>
        </center>
      </div>
      <div class="sag">
        <h3>{{ lang.digerebatlar }}</h3>
        <div class="urunler-liste">
          <div class="urun"
               data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-delay="0"
               data-aos-duration="600"
               data-aos-easing="ease-in"
               data-aos-mirror="true"
               data-aos-offset="0"
               data-aos-once="false">
            <router-link class="foto" to>
              <img class="bg" src="../../../public/assets/img/urun-alt.jpg"/>
              <img :src="image" alt="">
              <h3>{{ product.name }}</h3>
            </router-link>
          </div>
          <div class="urun"
               v-if="o1"
               data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-delay="0"
               data-aos-duration="600"
               data-aos-easing="ease-in"
               data-aos-mirror="true"
               data-aos-offset="0"
               data-aos-once="false">
              <router-link class="foto" :to="'/urun/'+others.seo" >
                <img class="bg" src="../../../public/assets/img/urun-alt.jpg"/>
                <img :src="othersimage" alt="">
                <h3>{{ others.name }}</h3>
              </router-link>
          </div>
          <div class="urun"
               v-if="o2"
               data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-delay="0"
               data-aos-duration="600"
               data-aos-easing="ease-in"
               data-aos-mirror="true"
               data-aos-offset="0"
               data-aos-once="false">
              <router-link :to="'/urun/'+others2.seo" class="foto">
                <img class="bg" src="../../../public/assets/img/urun-alt.jpg"/>
                <img :src="others2image" alt="">
                <h3>{{ others2.name }}</h3>
              </router-link>
          </div>
        </div>

        <h3>{{ lang.urungorselleri }}</h3>

        <div class="urun-gorseller">
          <FsLightbox
              :onClose="fsClose"
              :onOpen="fsOpen"
              :onSlideChange="change"
              :slide="slide"
              :sources="product.img"
              :toggler="toggler"
              type="image"/>
          <img v-for="(item, index) in product.img" :src="item"
               data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-delay="0"
               data-aos-duration="600"
               data-aos-easing="ease-in"
               data-aos-mirror="true"
               data-aos-offset="0"
               data-aos-once="false"
               @click="openLightboxOnSlide(index+1)"/>
        </div>
      </div>
      <a :class="{df: sss}" class="downloadImage" @click="download()">
        <svg id="Capa_1" style="enable-background:new 0 0 30 30;" version="1.1" viewBox="0 0 30 30"
             x="0px" xml:space="preserve"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
          <g>
	<path class="st0" d="M25.5,19.1V26H4.5v-6.8h-4V28c0,1.1,0.9,2,2,2h25c1.1,0,2-0.9,2-2v-8.9H25.5z"/>
            <path class="st0" d="M14.6,18.4l-5.8-7c0,0-0.9-0.8,0.1-0.8s3.2,0,3.2,0s0-0.6,0-1.4c0-2.4,0-6.9,0-8.7c0,0-0.1-0.5,0.6-0.5
		c0.8,0,4,0,4.6,0c0.5,0,0.5,0.4,0.5,0.4c0,1.8,0,6.4,0,8.7c0,0.8,0,1.3,0,1.3s1.8,0,3,0c1.2,0,0.3,0.9,0.3,0.9s-4.9,6.5-5.6,7.2
		C15.1,19,14.6,18.4,14.6,18.4z"/>
</g>
</svg>
      </a>
    </section>
  </div>
</template>
<script>
import FsLightbox from "fslightbox-vue/v3";
import FileSaver from 'file-saver';
import iso from "../../axios";
export default {
  components: {FsLightbox},
  data() {
    return {
      sss: false,
      toggler: false,
      slide: 1,
      product: [],
      others: [],
      others2: [],
      lang:[],
      cats: '',
      image: '',
      othersimage: '',
      others2image: '',
      defaultProduct: '',
      o1:false,
      o2:false
    }
  },
  created() {
    this.defaultProduct = this.$route.params.product
    iso.get('langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods: {
    pGet() {
      iso.get('products/' + this.$route.params.product)
          .then(response => {
            this.product = response.data
            this.image = response.data.img[0]
            iso.get('categories/' + this.product.cat)
                .then(response => {
                  this.cats = response.data.heading
                    if (this.product.others) {
                      iso.get('products/' + this.product.others)
                          .then(response => {
                            this.others = response.data
                            this.othersimage = response.data.img[0]
                            this.o1 = true
                          })
                          .catch(error => {
                            console.log(error);
                          })
                        if (this.product.others2) {
                          iso.get('products/' + this.product.others2)
                              .then(response => {
                                this.others2 = response.data
                                this.others2image = response.data.img[0]
                                this.o2 = true
                              })
                              .catch(error => {
                                console.log(error);
                              })
                        }
                    }
                })
                .catch(error => {
                  console.log(error);
                })
          })
          .catch(error => {
            console.log(error);
          })
    },
    changeProduct(e) {
      iso.get('products/' + e)
          .then(response => {
            this.product = response.data
            this.image = response.data.img[0]
            iso.get('categories/' + this.product.cat)
                .then(response => {
                  this.cats = response.data.heading
                })
                .catch(error => {
                  console.log(error);
                })
          })
          .catch(error => {
            console.log(error);
          })
    },
    fsOpen: function () {
      this.sss = true
    },
    fsClose: function () {
      this.sss = false
    },
    openLightboxOnSlide: function (number) {
      this.slide = number;
      this.toggler = !this.toggler;
    },
    download() {
      document.querySelectorAll('.fslightbox-flex-centered').forEach(item => {
        if (getComputedStyle(item).transform == 'matrix(1, 0, 0, 1, 0, 0)') {
          const url = item.getElementsByTagName('img')[0].src
          FileSaver.saveAs(url, "image.jpg");
        }
      })
    },
  },
  mounted() {
    this.pGet()
  },
  watch: {
    $route(to, from) {
      this.pGet()
    }
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
.st0 {
  fill: #ddd;
}

.downloadImage {
  position: fixed;
  right: 90px;
  top: 0;
  z-index: 99999999;
  height: 45px;
  width: 45px;
  cursor: pointer;
  box-sizing: border-box;
  display: none;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: rgba(35, 35, 35, .65);
}

.downloadImage svg {
  width: 20px
}

.df {
  display: flex
}

.header-urundetay {
  position: relative;
  clear: both;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.header-urundetay .bg {
  flex: 2;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: -2;
}

.header-urundetay > div {
  position: relative;
  display: block;
  width: 100vw;
}

.header-urundetay > div > div {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 1600px;
}

.header-urundetay .baslik {
  flex: 1;
  color: #af1116;
  text-align: right;
  z-index: 0;
  padding-right: 20px;
}

.header-urundetay .baslik h3 {
  font-family: bukis;
  color: #af1116;
  font-size: 3vw;
}

.header-urundetay .baslik h2 {
  font-family: elyazi;
  font-size: 5vw;
  margin: 8px 0px 8px 0px;
  color: #af1116;
}

.header-urundetay .urun {
  flex: 1;
  width: 40%;
  float: left;
  margin-left: 20%;
  height: 28vw;
  color: #faf6f7;
}

.header-urundetay .urun img {
  position: relative;
}

.icerik-urundetay {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin: auto;
  padding-top: 8vw;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.icerik-urundetay h3 {
  padding: 0 20px;
  font-family: bukis;
  color: #af1116;
  font-size: 30px;
  margin-bottom: 1vw;
}

.icerik-urundetay p {
  padding: 0 20px;
  margin-bottom: 1vw;
}

.icerik-urundetay img {
  margin: 0 1vw 20px;
  border-radius: 2vw;
  width: calc(100% - 40px);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.icerik-urundetay .sol {
  flex: 1;
  min-width: 370px;
  float: left;
}

.icerik-urundetay .sag {
  flex: 1;
  min-width: 370px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 440px) {

}

.icerik-urundetay .sag .urunler-liste {
  width: 100%;
}

.icerik-urundetay .sag .urunler-liste .urun {
  width: 30%;
  float: left;
  position: relative;
  margin: 1%;
  /* border-radius: -99vw; */
}

.icerik-urundetay .sag .urunler-liste .urun .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}

.icerik-urundetay .sag .urunler-liste .urun .foto {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  text-decoration: none;

}

.icerik-urundetay .sag .urunler-liste .urun .foto img {
  width: 70%;
  box-shadow: none;
  margin: 0;
}

.icerik-urundetay .sag .urunler-liste .urun .foto img.bg {
  width: 100%;
}

.icerik-urundetay .sag .urunler-liste .urun h3 {
  color: #690c10;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: bolder;
  width: 100%;
  text-align: center;
}

.icerik-urundetay .sag .urun-gorseller {
  width: 100%;
  margin-top: 1vw;
  clear: both;
}

.icerik-urundetay .sag .urun-gorseller img {
  width: 31%;
  float: left;
  margin: 1%;
  border-radius: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

</style>